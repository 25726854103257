<template>
    <div>
        <smart-form
            v-if="formData && paymentTypes"
            :schema="formSchema"
            v-model="formData"
            :dataSets="{ paymentTypes }"
            :form-mode="formData.paymentType"
            :key="`paymentForm${formData.paymentType}`"
        />
        <toolbar
            :center-items="[
                {
                    name: 'savePayment',
                    label: 'Save',
                    preset: 'yes',
                    onClick: doSavePaymentDetails,
                    size: 'x-small',
                },
            ]"
        />
    </div>
</template>
<script>
const paymentTypes = ['EFT', 'BPAY', 'CHQ'];

const formSchema = {
    paymentType: {
        component: 'select-field',
        label: 'Payment Type',
        cols: 6,
        dataSets: { items: 'paymentTypes' },
    },
    blank1: {
        component: 'blank-field',
        cols: 6,
    },
    payee: {
        component: 'text-field',
        label: 'Payee',
        cols: 12,
        validModes: ['EFT'],
    },
    'eft.bsbNumber': {
        component: 'text-field',
        label: 'BSB',
        cols: 6,
        validModes: ['EFT'],
        mask: '###-###',
        maxlength: 7,
    },
    'eft.accountNumber': {
        component: 'text-field',
        label: 'Account',
        cols: 6,
        validModes: ['EFT'],
        mask: '#### #### #### #### ####',
    },
    bpayBillerCode: {
        component: 'text-field',
        label: 'BPay Biller Code',
        cols: 6,
        validModes: ['BPAY'],
        maskOptions: {
            numericOnly: true,
            maxLength: 10,
        },
    },
    paymentReference: {
        component: 'text-field',
        label: 'Reference',
        cols: 12,
        maskOptions: {
            alphaNumericOnly: true,
            allowCharacters: '- .',
            upperCase: true,
            maxLength: 20,
        },
    },
};

export default {
    components: {},
    props: {
        savePaymentDetails: Function,
        bill: Object,
    },
    data: () => ({
        formSchema,
        formData: null,
        paymentTypes,
        payment: null,
    }),
    methods: {
        async doSavePaymentDetails() {
            await this.savePaymentDetails(this.formData);
        },
        async getFormData() {
            const { payment = { paymentType: 'EFT' } } = this.bill;

            this.formData = { ...payment };
        },
    },
    mounted() {
        this.getFormData();
    },
};
</script>
