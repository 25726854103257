<template>
    <Page :title="title" icon="mdi-cash-lock-open">
        <div v-if="lines">
            <smart-table
                v-if="lines && lines.length"
                :headers="headers"
                :items="lines"
                class="elevation-1"
                dense
                item-key="itemKey"
                use-click-row-expand
                :items-per-page="9999"
                :itemsPerPageOptions="[9999]"
                hide-default-footer
                group-by="groupBy"
                is-filter
            >
                <template v-slot:group.header="{ items, isOpen, toggle }">
                    <th colspan="10">
                        <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }} </v-icon>
                        {{ items[0].groupBy }}
                    </th>
                </template>

                <template v-slot:expanded-item="{ item }">
                    <td colspan="8" :key="JSON.stringify(item)">
                        <div class="d-flex">
                            <span>
                                <!--                                    <pre>{{ JSON.stringify(item, null, 2) }}</pre>-->
                                <release-payments-add-form
                                    v-if="item.accountsBill.documentBalance > 0"
                                    :bill="item.accountsBill"
                                    :errorMessage="item.billApprove.errorMessage"
                                    :releasePayment="releasePayment"
                                    :defaultExpenseBankLink="getDefaultExpenseBankLinkForBill(item.accountsBill)"
                                    :banks="getBankList(item.accountsBill)"
                                    :key="`releasepaymentsaddform${JSON.stringify(item.accountsBill)}`"
                                />
                                <update-bill-payment-details
                                    v-if="item.accountsBill && !item.accountsBill.payment"
                                    :bill="item.accountsBill"
                                    :savePaymentDetails="savePaymentDetails(item.accountsBill)"
                                    :key="`updatebillpaymentdetails${JSON.stringify(item.accountsBill)}`"
                                />
                                <release-payments-list-releases
                                    :billPayments="getBillPayments(item)"
                                    :deleteBillPayment="deleteBillPayment"
                                    :key="`${JSON.stringify(item.billPayments)} ${item.accountsBill.documentBalance}`"
                                />
                                <note-form
                                    v-if="item.billApprove"
                                    collection-name="billApprove"
                                    :collection-id="item.billApprove.id"
                                />
                            </span>
                            <span>
                                <pdf-preview
                                    v-for="fileObj in item.attachables"
                                    :fileObj="fileObj"
                                    :key="JSON.stringify(fileObj)"
                                    color="#00F"
                                    width="100%"
                                />
                            </span>
                        </div>
                    </td>
                </template>
                <template v-slot:item.quickAction="{ item }">
                    <div>
                        <h5 style="color: red" v-if="item.billApprove.errorMessage">
                            {{ item.billApprove.errorMessage }}
                        </h5>
                        <toolbar
                            v-else-if="item.accountsBill.documentBalance > 0"
                            :center-items="[
                                {
                                    name: 'releasePayment',
                                    label: 'Release',
                                    preset: 'yes',
                                    onClick: () => releasePayment(item.accountsBill),
                                    size: 'x-small',
                                },
                            ]"
                        />
                    </div>
                </template>
            </smart-table>
            <div v-else>
                <h3>No Payments to Release</h3>
            </div>
            <!--            <pre>-->
            <!--                outstanding trans: {{ approvedTransactions.length }}-->
            <!--                bill payments: {{ billPayments.length }}-->
            <!--                {{ JSON.stringify(approvedTransactions[0], null, 2) }}-->
            <!--            </pre>-->
        </div>

        <!--        <payment-run-list  style="width: 800px;"  v-if="paymentRunData.unapproved"-->
        <!--                           :paymentRunList="paymentRunData.unapproved"-->
        <!--                           :key = "JSON.stringify(paymentRunData.unapproved)"/>-->
    </Page>
</template>

<script>
import api from '@/api';

import NoteForm from '@/components/common/note/NoteForm';
import PdfPreview from '@/components/common/PdfPreview';

import ReleasePaymentsAddForm from '@/views/accounts/bill/releasePayments/AddForm';
import ReleasePaymentsListReleases from '@/views/accounts/bill/releasePayments/ListReleases';
import UpdateBillPaymentDetails from '@/views/accounts/bill/releasePayments/UpdateBillPayment';

import objectUtil from '@/../common/utils/object';

const headers = [
    {
        text: 'Company',
        align: 'left',
        sortable: false,
        value: 'accountsBill.companyLink.text',
    },
    {
        text: 'Date',
        align: 'center',
        sortable: false,
        value: 'accountsBill.documentDate',
    },
    {
        text: 'Due',
        align: 'center',
        sortable: false,
        value: 'accountsBill.dueDate',
    },
    {
        text: 'Reference',
        align: 'left',
        sortable: false,
        value: 'accountsBill.documentReference',
    },
    {
        text: 'Total Amount',
        align: 'right',
        sortable: false,
        value: 'accountsBill.documentTotal',
        customComponent: 'Currency',
    },
    {
        text: 'Balance Owing',
        align: 'right',
        sortable: false,
        value: 'accountsBill.documentBalance',
        customComponent: 'Currency',
    },
    {
        text: 'Quick Action',
        value: 'quickAction',
    },
];

export default {
    components: {
        NoteForm,
        PdfPreview,
        ReleasePaymentsAddForm,
        ReleasePaymentsListReleases,
        UpdateBillPaymentDetails,
    },
    data: () => ({
        title: 'Release Payments',
        lines: null,
        headers,
        banks: null,
        companies: null,
        useKey: null,
    }),
    methods: {
        getLineFromTransaction(oneTransaction) {
            try {
                const groupBy = `${objectUtil.getObjectFieldByString(
                    'accountsBill.vendorLink.data.vendorCode',
                    oneTransaction,
                )} - ${objectUtil.getObjectFieldByString('accountsBill.payment.payee', oneTransaction)} - ${
                    objectUtil.getObjectFieldByString('accountsBill.payment.paymentType', oneTransaction) ||
                    `No Payment Details`
                }`;

                const itemKey = `${oneTransaction.accountsBill.companyLink.id}
                                    ${oneTransaction.accountsBill.accountsEntityName}
                                    ${oneTransaction.accountsBill.accountsId}
                                    ${oneTransaction.accountsBill.documentBalance}
                                    ${groupBy}`;

                return {
                    ...oneTransaction,
                    itemKey,
                    groupBy,
                    searchFields: `
                        ${objectUtil.getObjectFieldByString('accountsBill.companyLink.text', oneTransaction)}
                        ${objectUtil.getObjectFieldByString('accountsBill.documentDate', oneTransaction)}
                        ${objectUtil.getObjectFieldByString('accountsBill.dueDate ', oneTransaction)}
                        ${objectUtil.getObjectFieldByString('accountsBill.documentReference', oneTransaction)}
                        ${objectUtil.getObjectFieldByString('accountsBill.documentTotal', oneTransaction)}
                        ${objectUtil.getObjectFieldByString('accountsBill.documentBalance', oneTransaction)}
                    ${groupBy}
                        `,
                };
            } catch (e) {
                return {
                    ...oneTransaction,
                    itemKey: `${JSON.stringify(oneTransaction)}`,
                    groupBy: `~~~ERROR TRANSACTIONS~~~ ${e.message}`,
                };
            }
        },
        async loadReleasePaymentsData() {
            const result = await api.get(this.$store, 'bill/releasepayment');
            if (result.data) {
                this.lines = result.data.accountsBillsWithApprovalsWithPayments.map((oneTransaction) =>
                    this.getLineFromTransaction(oneTransaction),
                );
                this.banks = result.data.banks;
                this.companies = result.data.companies;
                console.log('loadReleasePayments', this.lines);
            }
        },
        async releasePayment(bill, releaseDate, paymentAmount, bankLink) {
            this.$store.dispatch('setLoading', 'Releasing Payment');

            const result = await api.post(this.$store, 'bill/releasepayment', {
                bill,
                releaseDate,
                paymentAmount,
                bankId: bankLink ? bankLink.id : undefined,
            });
            const { billPayment, errorMessage } = result.data;

            if (errorMessage) {
                this.$store.dispatch('setError', errorMessage);
            }
            if (billPayment) {
                const lines = this.lines.map((oneLine) => {
                    if (
                        oneLine.accountsBill.companyLink.id === billPayment.companyLink.id &&
                        oneLine.accountsBill.accountsEntityName === billPayment.accountsEntityName &&
                        oneLine.accountsBill.accountsId === billPayment.accountsId
                    ) {
                        oneLine.billPayments.push(billPayment);
                    }
                    return oneLine;
                });
                console.log('releasePayment', billPayment);
                this.adjustBalance(lines, billPayment);
            }

            this.$store.dispatch('setLoading');
        },
        async deleteBillPayment(billPayment) {
            console.log('deleteBillPayment 1', billPayment);
            const result = await api.delete(this.$store, `bill/releasepayment/${billPayment.id}`);
            console.log('deleteBillPayment result', result.data);
            if (result.data && result.data.success) {
                this.lines.map((oneLine) => {
                    const billPayments = oneLine.billPayments.filter(
                        (oneBillPayment) => oneBillPayment.id !== billPayment.id,
                    );

                    oneLine.billPayments = billPayments;
                    // return {
                    //     accountsBill: oneLine.accountsBill,
                    //     billApprove: oneLine.billApprove,
                    //     billPayments,
                    // };
                });
                console.log('deleteBillPayment 2', billPayment);
                // const billPayment = this.getLineFromTransaction(transaction)
                this.adjustBalance(this.lines, billPayment, true);
            } else {
                this.$store.dispatch('setError', result.data.errorMessage);
            }
            // SHOULD RETURN SAME AS loadReleasePaymentData()
        },
        getBillPayments(item) {
            const bill = this.lines.find(
                (oneLine) =>
                    oneLine.accountsBill.companyLink.id === item.accountsBill.companyLink.id &&
                    oneLine.accountsBill.accountsEntityName === item.accountsBill.accountsEntityName &&
                    oneLine.accountsBill.accountsId === item.accountsBill.accountsId,
            );
            return bill.billPayments || [];
        },
        adjustBalance(lines, billPayment, isDelete = false) {
            const factor = isDelete ? -1 : 1;
            this.lines = lines.map((oneLine) => {
                if (
                    oneLine.accountsBill.companyLink.id === billPayment.companyLink.id &&
                    oneLine.accountsBill.accountsEntityName === billPayment.accountsEntityName &&
                    oneLine.accountsBill.accountsId === billPayment.accountsId &&
                    !billPayment.exported
                ) {
                    oneLine.accountsBill.documentBalance =
                        oneLine.accountsBill.documentBalance - billPayment.paymentAmount * factor;
                    return this.getLineFromTransaction(oneLine);
                }

                return oneLine;
            });
        },
        getDefaultExpenseBankLinkForBill(bill) {
            const company = this.companies.find((oneCompany) => oneCompany.id === bill.companyLink.id);
            return company.defaultExpenseBankLink;
        },
        getBankList(bill) {
            return this.banks
                .filter((oneBank) => oneBank.companyLink.id === bill.companyLink.id)
                .map((oneBank) => ({
                    text: `${oneBank.bankCode} ${oneBank.account && oneBank.account.accountNumber}`,
                    id: oneBank.id,
                }));
        },
        savePaymentDetails(bill) {
            return async (payment) => {
                if (!payment) return;

                const result = await api.post(this.$store, 'bill/approve/updatepayment', {
                    companyId: bill.companyLink.id,
                    accountsEntityName: bill.accountsEntityName,
                    accountsId: bill.accountsId,
                    payment,
                });
                const { success, billApprove: updatedBillApprove } = result.data;
                if (success) {
                    this.lines.map((oneLine, counter) => {
                        if (
                            oneLine.accountsBill.companyLink.id === updatedBillApprove.companyLink.id &&
                            oneLine.accountsBill.accountsEntityName === updatedBillApprove.accountsEntityName &&
                            oneLine.accountsBill.accountsId === updatedBillApprove.accountsId
                        ) {
                            const updatedLine = this.getLineFromTransaction(
                                objectUtil.setObjectFieldByString(
                                    'accountsBill.payment',
                                    oneLine,
                                    updatedBillApprove.billData.payment,
                                ),
                            );
                            // deliberately updating the existing this.lines rather than setting
                            // this.lines = this.lines.map.... for reactivity reasons
                            const { accountsBill, itemKey, groupBy } = updatedLine;
                            const { payment } = accountsBill;
                            this.lines[counter].accountsBill.payment = payment;
                            this.lines[counter].billApprove.errorMessage = '';
                            this.lines[counter].itemKey = itemKey;
                            this.lines[counter].groupBy = groupBy;
                        }
                    });
                    this.useKey = JSON.stringify(this.lines);
                }
            };
        },
    },
    async mounted() {
        this.$store.dispatch('setLoading', 'Loading Bill Data');
        await this.loadReleasePaymentsData();
        this.$store.dispatch('setLoading');
    },
};
</script>

<style scoped></style>