var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[(!_vm.errorMessage && _vm.formData && _vm.formSchema)?_c('smart-form',{attrs:{"schema":_vm.formSchema,"dataSets":{ banks: _vm.banks }},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}}):_vm._e(),(_vm.errorMessage)?_c('h3',{staticStyle:{"color":"red","text-align":"center","width":"100%"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_c('div',{staticClass:"d-flex flex-row"},[_c('toolbar',{attrs:{"center-items":[
                {
                    name: 'releasePayment',
                    label: 'Release',
                    preset: 'yes',
                    onClick: _vm.doReleasePayment,
                    size: 'x-small',
                },
            ]},scopedSlots:_vm._u([{key:"centerItems",fn:function(){return [_c('t-e-button-link',{attrs:{"company-id":_vm.bill.companyLink.id,"accounts-entity-name":_vm.bill.accountsEntityName,"accounts-id":_vm.bill.accountsId,"size":"x-small"}})]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }