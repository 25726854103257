<template>
    <fragment>
        <smart-form
            v-if="!errorMessage && formData && formSchema"
            :schema="formSchema"
            v-model="formData"
            :dataSets="{ banks }"
        />
        <h3 v-if="errorMessage" style="color: red; text-align: center; width: 100%">
            {{ errorMessage }}
        </h3>
        <div v-else class="d-flex flex-row">
            <toolbar
                :center-items="[
                    {
                        name: 'releasePayment',
                        label: 'Release',
                        preset: 'yes',
                        onClick: doReleasePayment,
                        size: 'x-small',
                    },
                ]"
            >
                <template v-slot:centerItems>
                    <t-e-button-link
                        :company-id="bill.companyLink.id"
                        :accounts-entity-name="bill.accountsEntityName"
                        :accounts-id="bill.accountsId"
                        size="x-small"
                    />
                </template>
            </toolbar>
        </div>
    </fragment>
</template>
<script>
import TEButtonLink from '../common/TEButtonLink';

import objectUtil from '@/../common/utils/object';

const mainFormSchema = {
    paymentReference: {
        component: 'text-field',
        label: 'Payment Reference',
        cols: 6,
        readOnly: true,
    },
    releaseDate: {
        component: 'date-field',
        label: 'Release Date',
        cols: 12,
    },
    bankLink: {
        component: 'select-field',
        label: 'Bank',
        cols: 6,
        dataSets: { items: 'banks' },
    },
    paymentAmount: {
        component: 'currency-field',
        label: 'Payment Amount',
        precision: 2,
        cols: 6,
    },
};

const eftFormSchema = {
    bsbNumber: {
        component: 'text-field',
        label: 'Vendor BSB',
        cols: 3,
        readOnly: true,
    },
    accountNumber: {
        component: 'text-field',
        label: 'Vendor Account',
        cols: 3,
        readOnly: true,
    },
};

const bpayFormSchema = {
    bpayBillerCode: {
        component: 'text-field',
        label: 'BPay Biller Code',
        cols: 6,
        readOnly: true,
    },
};

export default {
    props: {
        bill: Object,
        releasePayment: Function,
        banks: Array,
        defaultExpenseBankLink: Object,
        errorMessage: String,
    },
    data: () => ({
        mainFormSchema,
        eftFormSchema,
        bpayFormSchema,
        formSchema: null,
        formData: null,
    }),
    components: { TEButtonLink },
    methods: {
        doReleasePayment() {
            this.releasePayment(
                this.bill,
                this.formData.releaseDate,
                this.formData.paymentAmount,
                this.formData.bankLink,
            );
        },
        async getFormData() {
            this.formData = {
                releaseDate: this.bill.dueDate,
                // paymentAmount: 123.45,
                paymentAmount: this.bill.documentBalance,
                bankLink: this.defaultExpenseBankLink,
                bsbNumber: objectUtil.getObjectFieldByString('payment.eft.bsbNumber', this.bill),
                accountNumber: objectUtil.getObjectFieldByString(
                    'payment.eft.accountNumber',
                    this.bill,
                ),
                paymentReference: objectUtil.getObjectFieldByString(
                    'payment.paymentReference',
                    this.bill,
                ),
                bpayBillerCode: objectUtil.getObjectFieldByString(
                    'payment.bpayBillerCode',
                    this.bill,
                ),
            };
        },
    },
    mounted() {
        this.formSchema = {
            ...(this.bill.payment.paymentType === 'EFT' ? eftFormSchema : {}),
            ...(this.bill.payment.paymentType === 'BPAY' ? bpayFormSchema : {}),
            ...this.mainFormSchema,
        };
        this.getFormData();
        console.log('formData', this.formData);
    },
};
</script>
