<template>
    <div>
        <smart-table
            v-if="billPayments && billPayments.length"
            :headers="headers"
            :items="billPayments"
            dense
            :key="JSON.stringify(billPayments)"
            hide-default-footer
            :items-per-page="9999"
            :items-per-page-options="[9999]"
            item-key="id"
        >
            <template v-slot:item.action="{ item }">
                <!--                <debug v-if="item.exported" :value="item.exported" />-->
                <div v-if="item.exported">{{ item.exported.exportDate }}</div>
                <toolbar
                    v-else-if="!item.billPaymentRunId"
                    :right-items="[
                        {
                            name: 'delete',
                            preset: 'no',
                            label: 'Delete',
                            size: 'x-small',
                            onClick: () => deleteBillPayment(item),
                            confirm: 'Delete Scheduled Bill Payment?',
                            stopPrevent: true,
                        },
                    ]"
                />
            </template>
        </smart-table>
    </div>
</template>
<script>
const headers = [
    { text: 'Bank', align: 'left', sortable: false, value: 'bankLink.text' },
    { text: 'Release Date', align: 'center', sortable: false, value: 'releaseDate' },
    {
        text: 'Payment Amount',
        align: 'right',
        sortable: false,
        value: 'paymentAmount',
        customComponent: 'Currency',
        // xcustomComponent: 'Debug',
    },
    { text: 'Payment', value: 'action', align: 'center' },
];
export default {
    components: {},
    data: () => ({
        headers,
    }),
    props: {
        billPayments: Array,
        deleteBillPayment: Function,
    },
};
</script>
