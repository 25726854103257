var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.formData && _vm.paymentTypes)?_c('smart-form',{key:`paymentForm${_vm.formData.paymentType}`,attrs:{"schema":_vm.formSchema,"dataSets":{ paymentTypes: _vm.paymentTypes },"form-mode":_vm.formData.paymentType},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}}):_vm._e(),_c('toolbar',{attrs:{"center-items":[
            {
                name: 'savePayment',
                label: 'Save',
                preset: 'yes',
                onClick: _vm.doSavePaymentDetails,
                size: 'x-small',
            },
        ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }